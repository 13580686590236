import {BaseComponentLoader} from '@inventis/componentloader/src/BaseComponentLoader';

class ComponentLoader extends BaseComponentLoader {
    importComponent(name) {
        return import(`./scripts/${name}.js`);
    }
}

let loader = new ComponentLoader();
loader.loadComponents(document.body);
